#copyright-policy,
#privacy-policy,
#tos {
  font-family: "Roboto", sans-serif;
  margin-bottom: 80px;
}
#copyright-policy div > ol,
#privacy-policy div > ol,
#tos div > ol {
  counter-reset: list-counter;
}
#copyright-policy ol,
#privacy-policy ol,
#tos ol {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
#copyright-policy ol li,
#privacy-policy ol li,
#tos ol li {
  font-size: 24px;
  letter-spacing: 1.12px;
  font-weight: bolder;
  padding: 48px 0;
  border-top: none !important;
}
#copyright-policy ol li:before,
#privacy-policy ol li:before,
#tos ol li:before {
  content: counter(list-counter) ". ";
  counter-increment: list-counter;
  color: rgb(49, 15, 162);
  font-size: 24px;
  letter-spacing: 1.38px;
}
@media (max-width: 992px) {
  #copyright-policy ol li:before,
  #privacy-policy ol li:before,
  #tos ol li:before {
    font-size: 18px;
  }
}
#copyright-policy ol li ol li,
#privacy-policy ol li ol li,
#tos ol li ol li {
  padding: 20px 0 0 2.6rem;
  font-family: "Playfair Display", serif;
  font-size: 16px;
  letter-spacing: 0.98px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  border-top: 0;
}
@media (max-width: 900px) {
  #copyright-policy ol li ol li,
  #privacy-policy ol li ol li,
  #tos ol li ol li {
    padding: 20px 0 0 0;
    word-break: break-word;
  }
}
#copyright-policy ol li ol li:not(:first-child),
#privacy-policy ol li ol li:not(:first-child),
#tos ol li ol li:not(:first-child) {
  border: none;
}
#copyright-policy ol li ol li:last-child,
#privacy-policy ol li ol li:last-child,
#tos ol li ol li:last-child {
  border: none;
}
#copyright-policy ol li ol li:before,
#privacy-policy ol li ol li:before,
#tos ol li ol li:before {
  content: none;
}
#copyright-policy ol li ol li ol,
#privacy-policy ol li ol li ol,
#tos ol li ol li ol {
  counter-reset: sublist-counter;
  margin-left: 48px;
}
#copyright-policy ol li ol li ol li,
#privacy-policy ol li ol li ol li,
#tos ol li ol li ol li {
  padding-left: 0;
}
#copyright-policy ol li ol li ol li:before,
#privacy-policy ol li ol li ol li:before,
#tos ol li ol li ol li:before {
  font-size: 18px;
  counter-increment: sublist-counter;
  content: counter(list-counter) "." counter(sublist-counter) " ";
}
@media (max-width: 992px) {
  #copyright-policy ol li ol li ol li:before,
  #privacy-policy ol li ol li ol li:before,
  #tos ol li ol li ol li:before {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  #copyright-policy,
  #privacy-policy,
  #tos {
    margin-bottom: 60px;
  }
}
@media (max-width: 576px) {
  #copyright-policy,
  #privacy-policy,
  #tos {
    margin-bottom: 40px;
  }
}
#copyright-policy .header,
#privacy-policy .header,
#tos .header {
  margin-top: 65px;
  padding: 160px 0 40px;
  border-block: 1px solid rgb(228, 228, 228);
}
@media (max-width: 768px) {
  #copyright-policy .header,
  #privacy-policy .header,
  #tos .header {
    padding: 60px 0 40px;
    border-bottom: none;
    background: transparent !important;
  }
}
@media (max-width: 576px) {
  #copyright-policy .header,
  #privacy-policy .header,
  #tos .header {
    padding-top: 40px;
    margin-top: 60px;
  }
}
#copyright-policy hr,
#privacy-policy hr,
#tos hr {
  margin: 40px 0;
  border-top: 1px solid rgb(49, 15, 162);
}
#copyright-policy .header-title,
#privacy-policy .header-title,
#tos .header-title {
  max-width: 766px;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 1.28px;
  font-weight: 700;
  margin: 0 auto;
}
#copyright-policy .header-title p,
#privacy-policy .header-title p,
#tos .header-title p {
  margin: 0;
}
@media (max-width: 768px) {
  #copyright-policy .header-title,
  #privacy-policy .header-title,
  #tos .header-title {
    font-size: 32px;
    line-height: 36px;
    width: 95%;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  #copyright-policy .header-title,
  #privacy-policy .header-title,
  #tos .header-title {
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    max-width: 336px;
  }
}
@media (1400px <= width) {
  #copyright-policy .header-title,
  #privacy-policy .header-title,
  #tos .header-title {
    margin-left: 23.07%;
  }
}
#copyright-policy .tos-body,
#privacy-policy .tos-body,
#tos .tos-body {
  width: 95%;
  max-width: 763px;
  margin: 0 auto;
}
#copyright-policy .tos-body p,
#privacy-policy .tos-body p,
#tos .tos-body p {
  line-height: 19px;
  letter-spacing: 0.035px;
  color: rgb(6, 6, 6);
}
#copyright-policy .tos-body .margened-paragraph p:has(strong),
#privacy-policy .tos-body .margened-paragraph p:has(strong),
#tos .tos-body .margened-paragraph p:has(strong) {
  margin-left: 40px;
}
#copyright-policy .tos-body .margened-paragraph p:nth-of-type(21):has(strong),
#privacy-policy .tos-body .margened-paragraph p:nth-of-type(21):has(strong),
#tos .tos-body .margened-paragraph p:nth-of-type(21):has(strong) {
  margin-left: 0;
}
#copyright-policy .tos-body .margened-paragraph p:nth-of-type(26):has(strong),
#privacy-policy .tos-body .margened-paragraph p:nth-of-type(26):has(strong),
#tos .tos-body .margened-paragraph p:nth-of-type(26):has(strong) {
  margin-left: 0;
}
#copyright-policy .tos-body .TOSmargened-paragraph p:has(strong),
#privacy-policy .tos-body .TOSmargened-paragraph p:has(strong),
#tos .tos-body .TOSmargened-paragraph p:has(strong) {
  margin-left: 40px;
}
#copyright-policy .tos-body p:not(.text-content):has(strong) strong,
#privacy-policy .tos-body p:not(.text-content):has(strong) strong,
#tos .tos-body p:not(.text-content):has(strong) strong {
  line-height: normal;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  font-weight: bold !important;
}
@media (1400px <= width) {
  #copyright-policy .tos-body,
  #privacy-policy .tos-body,
  #tos .tos-body {
    margin-left: 23.07%;
  }
}
@media (max-width: 992px) {
  #copyright-policy .tos-body,
  #privacy-policy .tos-body,
  #tos .tos-body {
    max-width: 728px;
  }
}
@media (max-width: 768px) {
  #copyright-policy .tos-body,
  #privacy-policy .tos-body,
  #tos .tos-body {
    border-top: 1px solid rgb(228, 228, 228);
  }
}
@media (max-width: 576px) {
  #copyright-policy .tos-body,
  #privacy-policy .tos-body,
  #tos .tos-body {
    width: 100%;
    max-width: 335px;
    border-top: 1px solid rgb(228, 228, 228);
  }
}
#copyright-policy .tos-body .text-title p,
#privacy-policy .tos-body .text-title p,
#tos .tos-body .text-title p {
  line-height: normal;
  font-size: 24px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
@media (992px <= width) {
  #copyright-policy .tos-body .text-title p,
  #privacy-policy .tos-body .text-title p,
  #tos .tos-body .text-title p {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  #copyright-policy .tos-body .text-title p,
  #privacy-policy .tos-body .text-title p,
  #tos .tos-body .text-title p {
    font-size: 20px;
  }
}
#copyright-policy .tos-body p,
#copyright-policy .tos-body .text-content p,
#copyright-policy .tos-body .text-content p:has(strong) strong,
#privacy-policy .tos-body p,
#privacy-policy .tos-body .text-content p,
#privacy-policy .tos-body .text-content p:has(strong) strong,
#tos .tos-body p,
#tos .tos-body .text-content p,
#tos .tos-body .text-content p:has(strong) strong {
  line-height: 32px;
  letter-spacing: 0.512px;
  color: rgb(6, 6, 6);
  font-size: 16px;
  font-weight: 400;
}
#copyright-policy .tos-body .tos-main-content p,
#privacy-policy .tos-body .tos-main-content p,
#tos .tos-body .tos-main-content p {
  margin-bottom: 40px !important;
}
#copyright-policy .last-update p,
#privacy-policy .last-update p,
#tos .last-update p {
  color: rgb(49, 15, 162);
  font-size: 16px;
  margin: 40px 0 20px 0;
  font-weight: 600;
  line-height: 24px;
}
#copyright-policy .tos-main-content,
#privacy-policy .tos-main-content,
#tos .tos-main-content {
  font-weight: 400px;
  width: 100%;
  letter-spacing: 0.51px;
}
#copyright-policy .divider,
#privacy-policy .divider,
#tos .divider {
  width: 100%;
  height: 1px;
  background: rgb(76, 96, 133);
  margin-top: 30px;
  margin-bottom: 30px;
}
#copyright-policy .text-title p,
#privacy-policy .text-title p,
#tos .text-title p {
  font-size: 24px;
  color: rgb(49, 15, 162);
  line-height: 28px;
  letter-spacing: 0.77px;
  margin-bottom: 20px;
  font-weight: 600;
}
#copyright-policy .text-content p,
#copyright-policy ul,
#copyright-policy ol,
#privacy-policy .text-content p,
#privacy-policy ul,
#privacy-policy ol,
#tos .text-content p,
#tos ul,
#tos ol {
  font-size: 16px;
  margin-bottom: 20px;
}
#copyright-policy .text-content p a,
#copyright-policy ul a,
#copyright-policy ol a,
#privacy-policy .text-content p a,
#privacy-policy ul a,
#privacy-policy ol a,
#tos .text-content p a,
#tos ul a,
#tos ol a {
  color: rgb(49, 15, 162);
}
#copyright-policy .text-content p a:hover,
#copyright-policy ul a:hover,
#copyright-policy ol a:hover,
#privacy-policy .text-content p a:hover,
#privacy-policy ul a:hover,
#privacy-policy ol a:hover,
#tos .text-content p a:hover,
#tos ul a:hover,
#tos ol a:hover {
  text-decoration: underline !important;
}
#copyright-policy .text-content:not(:last-child)::after,
#privacy-policy .text-content:not(:last-child)::after,
#tos .text-content:not(:last-child)::after {
  content: "";
  display: block;
  width: 100%;
  margin: 40px auto;
  height: 0.5px;
  background-color: rgb(231, 231, 231);
}
#copyright-policy ol, #copyright-policy ul,
#privacy-policy ol,
#privacy-policy ul,
#tos ol,
#tos ul {
  margin-left: 40px;
}
#copyright-policy ul, #copyright-policy ol,
#privacy-policy ul,
#privacy-policy ol,
#tos ul,
#tos ol {
  margin-left: 60px;
  padding: 0;
  line-height: 32px;
  margin-left: 40px;
}
@media (992px <= width) {
  #copyright-policy ul, #copyright-policy ol,
  #privacy-policy ul,
  #privacy-policy ol,
  #tos ul,
  #tos ol {
    font-size: 16px;
  }
}
#copyright-policy ul li:not(:last-child), #copyright-policy ol li:not(:last-child),
#privacy-policy ul li:not(:last-child),
#privacy-policy ol li:not(:last-child),
#tos ul li:not(:last-child),
#tos ol li:not(:last-child) {
  margin-bottom: 20px;
}
#copyright-policy ul li:first-child, #copyright-policy ol li:first-child,
#privacy-policy ul li:first-child,
#privacy-policy ol li:first-child,
#tos ul li:first-child,
#tos ol li:first-child {
  margin-top: 20px;
}
#copyright-policy ul li, #copyright-policy ol li,
#privacy-policy ul li,
#privacy-policy ol li,
#tos ul li,
#tos ol li {
  font-size: 16px;
  font-weight: 400;
  color: rgb(6, 6, 6);
  padding-top: 0;
  padding-bottom: 0;
}
#copyright-policy ul li:before, #copyright-policy ol li:before,
#privacy-policy ul li:before,
#privacy-policy ol li:before,
#tos ul li:before,
#tos ol li:before {
  content: counter(list-counter) ". ";
  counter-increment: list-counter;
  color: rgb(49, 15, 162);
  font-size: 16px;
  letter-spacing: 1.38px;
}
@media (max-width: 992px) {
  #copyright-policy ul li:before, #copyright-policy ol li:before,
  #privacy-policy ul li:before,
  #privacy-policy ol li:before,
  #tos ul li:before,
  #tos ol li:before {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  #copyright-policy ul li, #copyright-policy ol li,
  #privacy-policy ul li,
  #privacy-policy ol li,
  #tos ul li,
  #tos ol li {
    font-size: 16px;
  }
}
#copyright-policy ul li ol, #copyright-policy ol li ol,
#privacy-policy ul li ol,
#privacy-policy ol li ol,
#tos ul li ol,
#tos ol li ol {
  counter-reset: sublist-counter;
  margin-left: 40px;
}
#copyright-policy ul li ol li, #copyright-policy ol li ol li,
#privacy-policy ul li ol li,
#privacy-policy ol li ol li,
#tos ul li ol li,
#tos ol li ol li {
  padding-left: 0;
  font-size: 16px;
}
@media (max-width: 768px) {
  #copyright-policy ul li ol li, #copyright-policy ol li ol li,
  #privacy-policy ul li ol li,
  #privacy-policy ol li ol li,
  #tos ul li ol li,
  #tos ol li ol li {
    font-size: 14px;
  }
}
#copyright-policy ul li ol li:before, #copyright-policy ol li ol li:before,
#privacy-policy ul li ol li:before,
#privacy-policy ol li ol li:before,
#tos ul li ol li:before,
#tos ol li ol li:before {
  content: counter(list-counter) "." counter(sublist-counter) " ";
  counter-increment: sublist-counter;
  font: 16px/1.38px Roboto, sans-serif;
  color: rgb(49, 15, 162);
}
@media (max-width: 992px) {
  #copyright-policy ul li ol li:before, #copyright-policy ol li ol li:before,
  #privacy-policy ul li ol li:before,
  #privacy-policy ol li ol li:before,
  #tos ul li ol li:before,
  #tos ol li ol li:before {
    font-size: 18px;
  }
}