#copyright-policy,
#privacy-policy,
#tos {

    // List counter
    div>ol {
        counter-reset: list-counter;
    }

    ol {
        list-style: none;
        margin-bottom: 0;

        padding-left: 0;

        // Section Headers
        li {
            font-size: $extralarge;
            letter-spacing: 1.12px;
            font-weight: bolder;
            padding: $extrahugeplus 0;
            border-top: none !important;

            &:before {
                content: counter(list-counter) ". ";
                counter-increment: list-counter;
                color: $purple-text;
                font-size: $extralarge;
                letter-spacing: 1.38px;

                @media (max-width: $desktop) {
                    font-size: $large;
                }
            }

            // Section Paragraphs
            ol li {
                @media (max-width: 900px) {
                    padding: $largeplus 0 0 0;
                    word-break: break-word;
                }

                padding: $largeplus 0 0 2.6rem;
                font-family: $secondaryfont;
                font-size: $medium;
                letter-spacing: 0.98px;
                font-weight: normal;
                color: $gray;
                border-top: 0;

                &:not(:first-child) {
                    border: none;
                }

                &:last-child {
                    border: none;
                }

                &:before {
                    content: none;
                }

                // Subsections
                ol {
                    counter-reset: sublist-counter;
                    margin-left: $extrahugeplus;

                    li {
                        padding-left: 0;

                        &:before {
                            font-size: 18px;
                            counter-increment: sublist-counter;
                            content: counter(list-counter) "." counter(sublist-counter) " ";

                            @media (max-width: $desktop) {
                                font-size: $regular;
                            }
                        }
                    }
                }
            }
        }
    }


    font-family: $primaryfont;
    margin-bottom: $hugePad;

    @media (max-width: $tablet) {
        margin-bottom: $largePad;
    }

    @media (max-width: $phone) {
        margin-bottom: $bigPad;
    }

    .header {
        margin-top: 65px;
        padding: 160px 0 $bigPad;
        border-block: 1px solid $color-grey-400;

        @media (max-width: $tablet) {
            padding: $largePad 0 $bigPad;
            border-bottom: none;
            background: transparent !important;
        }

        @media (max-width: $phone) {
            padding-top: $bigPad;
            margin-top: $largePad;
        }


    }

    hr {
        margin: $bigPad 0;
        border-top: 1px solid $purple-text;
    }

    .header-title {
        max-width: 766px;
        font-size: $moreplushuge;
        line-height: $extrahuge;
        letter-spacing: 1.28px;
        font-weight: 700;
        margin: 0 auto;

        p {
            margin: 0;
        }

        @media (max-width: $tablet) {
            font-size: $moreplushuge;
            line-height: $extrahuge;
            width: 95%;
            margin: 0 auto;
        }

        @media (max-width: $phone) {
            font-size: $extralarge;
            line-height: $huge;
            width: 100%;
            max-width: 336px;
        }

        @media ($extrascreen <=width) {
            margin-left: 23.07%;
        }
    }

    .tos-body {
        width: 95%;
        max-width: 763px;
        margin: 0 auto;

        p {
            line-height: 19px;
            letter-spacing: 0.035px;
            color: $main-title-without-bg;
        }

        .margened-paragraph {
            p:has(strong) {
                margin-left: $extrahugetitle;
            }

            p:nth-of-type(21):has(strong) {
                margin-left: 0;
            }

            p:nth-of-type(26):has(strong) {
                margin-left: 0;
            }
        }

        .TOSmargened-paragraph {
            p:has(strong) {
                margin-left: $extrahugetitle;
            }
        }

        p:not(.text-content):has(strong) strong {
            line-height: normal;
            font-size: $medium !important;
            letter-spacing: 0.5px;
            font-weight: bold !important;
        }


        @media ($extrascreen <=width) {
            margin-left: 23.07%;
        }

        @media (max-width: $desktop) {
            max-width: 728px;
        }

        @media (max-width: $tablet) {
            border-top: 1px solid $color-grey-400;
        }

        @media (max-width: $phone) {
            width: 100%;
            max-width: 335px;
            border-top: 1px solid $color-grey-400;
        }

        .text-title p {
            line-height: normal;
            font-size: $extralarge;
            letter-spacing: 0.5px;
            font-weight: 500;

            @media ($desktop <=width) {
                font-size: $extralarge;
            }

            @media (max-width: $phone) {
                font-size: $largeplus;
            }
        }

        p,
        .text-content p,
        .text-content p:has(strong) strong {
            line-height: $moreplushuge;
            letter-spacing: 0.512px;
            color: $color-grey-1100;
            font-size: $medium;
            font-weight: 400;
        }

        .text-content:last-child p {}

        .tos-main-content p {
            margin-bottom: $bigPad !important;
        }
    }

    .last-update p {
        color: $purple-text;
        font-size: $medium;
        margin: 40px 0 20px 0;
        font-weight: 600;
        line-height: $extralarge;
    }

    .tos-main-content {
        font-weight: 400px;
        width: 100%;
        letter-spacing: 0.51px;
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $primaryborder;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .text-title p {
        font-size: $extralarge;
        color: $purple-text;
        line-height: 28px;
        letter-spacing: 0.77px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .text-content p,
    ul,
    ol {
        font-size: $medium;
        margin-bottom: 20px;

        a {
            color: $purple-text;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .text-content:not(:last-child)::after {
        content: '';
        display: block;
        width: 100%;
        margin: 40px auto;
        height: .5px;
        background-color: $color-grey-300;
    }


    ol,ul {
        margin-left: $bigPad;
    }

    ul,ol {
        margin-left: $largePad;
        padding: 0;
        line-height: $moreplushuge;
        margin-left: $extrahugetitle;

        @media ($desktop <=width) {
            font-size: $medium;
        }

        li:not(:last-child) {
            margin-bottom: $largeplus;
        }

        li:first-child {
            margin-top: $largeplus;
        }

        li {
            font-size: $medium;
            font-weight: 400;
            color: $color-grey-1100;
            padding-top: 0;
            padding-bottom: 0;

            &:before {
                content: counter(list-counter) ". ";
                counter-increment: list-counter;
                color: $purple-text;
                font-size: $medium;
                letter-spacing: 1.38px;

                @media (max-width: $desktop) {
                    font-size: $large;
                }
            }

            @media (max-width: $tablet) {
                font-size: $medium;
            }

            ol {
                counter-reset: sublist-counter;
                margin-left: 40px;

                li {
                    padding-left: 0;
                    font-size: $medium;

                    @media (max-width: $tablet) {
                        font-size: $regular;
                    }

                    &:before {
                        content: counter(list-counter) "." counter(sublist-counter) " ";
                        counter-increment: sublist-counter;
                        font: #{$medium}/1.38px #{$primaryfont};
                        color: $purple-text;
                        
                        @media (max-width: $desktop) {
                            font-size: $large;
                        }
                    }
                }
            }
        }
    }
}